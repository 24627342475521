
import { Options, Vue } from 'vue-class-component';

import QuestionForm from './components/QuestionForm.vue';
import store from '@/store';
import { postAnalytics } from '@/utils/api';

@Options({
    components: {
        QuestionForm
    },
    store
})
export default class Question extends Vue {
    isFormSubmitted = false;

    mounted(): void {
        postAnalytics({ eventName: 'page-open' });
    }

    onFormSubmitted(): void {
        this.$router.replace({
            path: '/post-question',
            query: this.$route.query
        });
    }
}
