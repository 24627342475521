import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "u-marginBottomUI--xSmall" }
const _hoisted_2 = { class: "c-form-row" }
const _hoisted_3 = { class: "c-form-col u-marginLeftUI--small" }
const _hoisted_4 = { class: "u-fontWeight--bold u-marginBottomUI--xxSmall u-fontSize-vue--medium" }
const _hoisted_5 = {
  class: "c-labelledFormBox-label",
  for: "question"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "u-marginBottomUI--xxxLarge" }
const _hoisted_8 = { class: "c-form-row u-marginBottomUI--xSmall" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = {
  key: 0,
  class: "c-loader c-loader--medium c-loader--buttonFontColor"
}
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_image = _resolveComponent("product-image")!
  const _component_profile = _resolveComponent("profile")!
  const _component_terms = _resolveComponent("terms")!
  const _component_powered_by_okendo = _resolveComponent("powered-by-okendo")!
  const _directive_analytics = _resolveDirective("analytics")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitQuestionForm && _ctx.submitQuestionForm(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_product_image, {
          imageUrl: _ctx.product.imageUrl,
          imageWidth: 80,
          imageHeight: 80,
          showPlaceholderIfEmpty: true,
          class: "c-form-col"
        }, null, 8, ["imageUrl"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.product.name), 1)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([{ hasError: !_ctx.questionBody && _ctx.hasSubmitted }, "c-labelledFormBox u-marginBottomUI--xxSmall"])
    }, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Question')), 1),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.questionBody) = $event)),
        placeholder: _ctx.questionPlaceholderText,
        class: "c-textarea c-labelledFormBox-input",
        id: "question",
        maxlength: "10000"
      }, null, 8, _hoisted_6), [
        [_vModelText, _ctx.questionBody],
        [_directive_analytics, {
                    eventName: 'change-question-body',
                    label: 'bodyLength'
                }, "change"]
      ])
    ], 2),
    _createElementVNode("fieldset", _hoisted_7, [
      _createVNode(_component_profile, { hasSubmitted: _ctx.hasSubmitted }, null, 8, ["hasSubmitted"])
    ]),
    _createVNode(_component_terms),
    _createElementVNode("div", _hoisted_8, [
      _withDirectives(_createElementVNode("button", {
        "aria-label": _ctx.$t('Submit'),
        class: "c-button"
      }, [
        (_ctx.isSending)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10))
          : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('Agree & Submit')), 1))
      ], 8, _hoisted_9), [
        [_directive_analytics, { eventName: 'click-submit-question' }]
      ])
    ]),
    _createVNode(_component_powered_by_okendo)
  ], 32))
}