import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_form = _resolveComponent("question-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_question_form, {
      onFormSubmitted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFormSubmitted()))
    })
  ]))
}